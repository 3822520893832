export const componentType = type => {
    type = parseInt(type)
    switch (type) {
        case 1:
            return 'ProductListSection'
        case 9:
            return 'Banner'
        case 11:
            return 'GridBanner'
        case 22:
        case 17:
            return 'ScrollableBanners'
        case 21:
            return 'TimerBanner'
        case 24:
            return 'VideoSlider'
        case 2:
        case 25:
            return 'ScrollableRows'
        default:
            return ''
    }
}

export const genderType = type => {
    const {t} = useI18n()
    type = parseInt(type)
    switch (type) {
        case 1:
            return t('auth.male')
        case 2:
            return t('auth.female')
        case 3:
            return t('auth.prefer_not_to_say')
    }
}

export const productSortOptions = () => {
    const {t} = useI18n()
    return [
        {name: t('category.sort.most_popular'), value: 'most_popular'},
        {name: t('category.sort.new'), value: 'new_first'},
        {name: t('category.sort.offers'), value: 'offer_first'},
        {
            name: t('category.sort.price_high_to_low'),
            value: 'price_high_to_low'
        },
        {
            name: t('category.sort.price_low_to_high'),
            value: 'price_low_to_high'
        }
    ]
}